import { useFirestoreOne } from "../../firebase/useFirestore"
import { useContext } from "react"
import { Auth } from "../../state/Auth"
import { useNavigate } from "react-router-dom"
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { collection, addDoc, serverTimestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config"
import { v4 as uuid } from 'uuid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ResearchOverview from "../../components/database/ResearchOverview"
import TotalMKBA from "../../components/mkba/TotalMKBA";

const EditDatabase = () => {
    const auth = useContext(Auth) 

    const navigate = useNavigate()

    const effects = useFirestoreOne('effects', 'deleted', false)

    const addEffect = async () => {

      const id = uuid()

      await addDoc(collection(db, "effects"), {
        title: 'Nieuw effect',
        timestamp: serverTimestamp(),
        id: id,
        parentId: '',
        deleted: false,
        sdg: [],
        sector: [],
      });

      await addDoc(collection(db, "logs"), {
        type: 'effect created',
        timestamp: serverTimestamp(),
        id: uuid(),
        parentId: id,
        userId: auth[0].id
      });

      await addDoc(collection(db, "mkbas"), {
        title: 'Nieuwe MKBA',
        timestamp: serverTimestamp(),
        id: uuid(),
        effect: id,
        deleted: false,
      });
    }

    const deleteEffect = async (e) => {

        const id = e.target.dataset.docid

        await updateDoc(doc(db, "effects", id), {
            deleted: true
        });
    }

  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>Beheer database</h1>
        </div>
         <div className='table-container'>
            <div className="add-icon-container" onClick={addEffect}>
              <AddCircleOutlineOutlinedIcon/>
              <p>Effect toevoegen</p>
            </div>
            <input type="text" placeholder="Zoek effect" />
            <table>
                <tr>
                    <th>TITEL</th>
                    <th>DOELGROEP</th>
                    <th>SDG</th>
                    <th>SECTOREN</th>
                    <th>TOTALE WAARDE</th>
                    <th>MEETMETHODE</th>
                    <th>AANPASSEN</th>
                    <th>VERWIJDEREN</th>
                </tr>
                {effects && effects.map(item => (
                    <tr key={item.ID}>
                    <td>
                        <p>{item.title}</p>
                   </td>
                   <td>
                      <p>{item.targetgroup}</p>
                   </td>
                   <td>
                      <p className='pointer' onClick={() => navigate(`/editeffect/${item.id}`)}>
                        <div className='database-sdg-container'>
                          <ul>
                            {item.sdg && item.sdg.map(sdg => (
                                <li>{sdg}</li>
                            ))}
                          </ul>
                        </div>
                      </p>
                   </td>
                   <td>
                      <p className='pointer' onClick={() => navigate(`/editeffect/${item.id}`)}>
                        <div className='database-sdg-container'>
                          <ul>
                            {item.sector && item.sector.map(sctr => (
                                <li>{sctr}</li>
                            ))}
                          </ul>
                        </div>
                      </p>
                   </td>
                   <td>
                      <p>€<TotalMKBA id={item.id}/></p>
                   </td>
                   <td>
                      <p className='pointer' onClick={() => navigate(`/editeffect/${item.id}`)}><ResearchOverview id={item.id}/></p>
                   </td>
                   <td>
                        <p className='pointer' onClick={() => navigate(`/editeffect/${item.id}`)}><EditOutlinedIcon/></p>
                   </td>
                   <td>
                      <p className='pointer' data-docid={item.docid} onClick={deleteEffect}><DeleteOutlineOutlinedIcon/></p>
                   </td>
               </tr>
                ))}
            </table>
        </div>
        
    </div>
  )
}

export default EditDatabase