import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAo2AYPQ74iBC9PtxMpJJ5UHNUUIRYmhcc",
  authDomain: "openmkba.firebaseapp.com",
  projectId: "openmkba",
  storageBucket: "openmkba.appspot.com",
  messagingSenderId: "623763064179",
  appId: "1:623763064179:web:4f9c76f276ddb21644f106",
  measurementId: "G-Z7TYDQC8SR"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
connectFunctionsEmulator(functions, "localhost", 5001);


export { db, auth, storage, analytics }