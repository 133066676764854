import Location from "../../hooks/Location"
import { useFirestoreId } from "../../firebase/useFirestore"
import { useState, useEffect } from "react"
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import TypeDetails from "../../components/questions/TypeDetails"
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';

const QuestionDetail = () => {
    const [title, setTitle] = useState('')
    const [type, setType] = useState('')
    const [effect, setEffect] = useState('')
    const [options, setOptions] = useState([])
    const [scaleStart, setScaleStart] = useState('')
    const [scaleEnd, setScaleEnd] = useState('')
    const [startDescription, setStartDescription] = useState('')
    const [endDescription, setEndDescription] = useState('')
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])

    const id = Location()[2]

    const questions = useFirestoreId('questions', id)

    useEffect(() => {
        questions && questions.map(item => (
            setTitle(item.title),
            setType(item.type),
            setEffect(item.effect),
            setOptions(item.options),
            setScaleStart(item.scaleStart),
            setScaleEnd(item.scaleEnd),
            setStartDescription(item.startDescription),
            setEndDescription(item.endDescription),
            setRows(item.rows),
            setColumns(item.columns)
        ))
    }, [questions])

    const questionType = (type) => {

        if(type === 'open') {
            return (
                <p>Open vraag</p>
            )
        } else if(type === 'scale') {
            return (
                <p>Schaalvraag</p>
            )
        } else if(type === 'multiple-one') {
            return (
                <p>Meerkeuzevraag (1 antwoord)</p>
            )
        } else if(type === 'multiple-multi') {
            return (
                <p>Meerkeuzevraag (meerdere antwoorden)</p>
            )
        } else if(type === 'matrix') {
            return (
                <p>Matrixvraag</p>
            )
        }
    }


  return (
    <div className='page-container'>
      <div className='page-title'>
          <h1>{title}</h1>
      </div>
      <div>
            {questions && questions.map(item => (
                <div key={item.id}>

                    {/* Description */}
                    <div className="effect-detail-section-container">
                        <div className="effect-detail-title-container">
                            <NotesOutlinedIcon/>
                            <h2>Omschrijving </h2>
                        </div>
                        <p>{item.description}</p>
                    </div>

                    {/* Type */}
                    <div className="effect-detail-section-container">
                        <div className="effect-detail-title-container">
                            <TuneOutlinedIcon/>
                            <h2>Type </h2>
                        </div>
                        <p>Schaalvraag</p>
                    </div>

                    {/* Typedetails */}
                    <div className="effect-detail-section-container">
                        <div className="effect-detail-title-container">
                            <ListOutlinedIcon/>
                            <h2>Antwoordopties </h2>
                        </div>
                        <div>
                            <div id='scale-value-container'>
                                <p>{item.scaleStart}</p>
                                <p>t/m</p>
                                <p>{item.scaleEnd}</p>
                            </div>
                            <div id='scale-value-container'>
                                <p>{item.startDescription}</p>
                                <p>{item.endDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default QuestionDetail