import { useNavigate } from "react-router-dom"
import { useFirestoreOne } from "../../firebase/useFirestore"
import ResearchOverview from "../../components/database/ResearchOverview"
import TotalMKBA from "../../components/mkba/TotalMKBA";

const Database = () => {

    const navigate = useNavigate()

    const effects = useFirestoreOne('effects', 'deleted', false)

  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>Database</h1>
        </div>
        <div>
            <div id='database-filter-container'>
                <div className="database-filter-item-container">
                    <p>SDG</p>
                    <select name="" id="">
                        <option value="">-- Selecteer een SDG --</option>
                    </select>
                </div>

                <div className="database-filter-item-container">
                    <p>Sector</p>
                    <select name="" id="">
                        <option value="">-- Selecteer een sector --</option>
                    </select>
                </div>
                
                <div className="database-filter-item-container">
                    <p>Doelgroep</p>
                    <select name="" id="">
                        <option value="">-- Selecteer een doelgroep --</option>
                    </select>
                </div>

                <div className="database-filter-item-container">
                    <p>Zoeken</p>
                    <input type="text" placeholder="Zoek effect" />
                </div>
            </div>
            
        </div>
        <div className='table-container'>
            <table>
                <tr>
                    <th>EFFECT</th>
                    <th>DOELGROEP</th>
                    <th>SDG'S</th>
                    <th>SECTOREN</th>
                    <th>MAATSCHAPPELIJKE WAARDE</th>
                    <th>MEETMETHODE</th>
                    <th>DETAILS</th>
                </tr>
                {effects && effects.map(item => (
                    <tr key={item.ID}>
                    <td>
                        <p>{item.title}</p>
                   </td>
                   <td>
                        <p>{item.targetgroup}</p>
                   </td>
                   <td>
                        <div className='database-sdg-container'>
                            <ul>
                                {item.sdg && item.sdg.map(sdg => (
                                    <li>{sdg}</li>
                                ))}
                            </ul>
                        </div>
                   </td>
                   <td>
                        <div className='database-sdg-container'>
                            <ul>
                                {item.sector && item.sector.map(sctr => (
                                    <li>{sctr}</li>
                                ))}
                            </ul>
                        </div>
                   </td>
                   <td>
                        <p>€<TotalMKBA id={item.id}/></p>
                   </td>
                   <td>
                        <ResearchOverview id={item.id}/>
                   </td>
                  
                   <td>
                       <p className='pointer' onClick={() => navigate(`/effectdetail/${item.id}`)}>Bekijk</p>
                   </td>
               </tr>
                ))}
            </table>
        </div>
        
    </div>
  )
}

export default Database