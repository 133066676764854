import Location from "../../hooks/Location"
import { useFirestoreId, useFirestoreTwo } from "../../firebase/useFirestore"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import TotalMKBA from "../../components/mkba/TotalMKBA";

function EffectDetail() {
  const [title, setTitle] = useState('')
  const [targetgroup, setTargetgroup] = useState('')
  const [sdgs, setSdgs] = useState([])

  const id = Location()[2]
  const navigate = useNavigate()

  const effects = useFirestoreId('effects', id ? id : '123')
  const questions = useFirestoreTwo('questions', 'effect', id, 'deleted', false)
  const moments = useFirestoreTwo('moments', 'effect', id, 'deleted', false)

  useEffect(() => {
    effects && effects.map(item => (
      setTitle(item.title),
      setTargetgroup(item.targetgroup),
      setSdgs(item.sdg)
    ))
  }, [effects])

  console.log(sdgs)

  return (
    <div className='page-container'>
      <div className='page-title'>
          <h1>{title}</h1>
      </div>
      <div>

      {/* Targetgroup */}
      <div className="effect-detail-section-container">
        <div className="effect-detail-title-container">
          <Groups2OutlinedIcon/>
          <h2>Doelgroep</h2>
        </div>
        <p>{targetgroup}</p>
      </div>

      {/* SDG's */}
      <div className="effect-detail-section-container">
        <div className="effect-detail-title-container">
          <WorkspacesOutlinedIcon/>
          <h2>SDG's</h2>
        </div>
        <div className="effect-detail-sdg-container">
          {sdgs && sdgs.map(sdg => (
            <p>{sdg}</p>
          ))}
        </div>
      </div>

      {/* MKBA */}
      <div className="effect-detail-section-container">
        <div className="effect-detail-title-container">
          <EuroOutlinedIcon/>
          <h2>MKBA</h2>
        </div>
        <p>Totale waarde per output:</p>
        <p><b>€<TotalMKBA id={id}/></b></p>
        <button onClick={() => navigate(`/mkbadetail/${id}`)}>Bekijken</button>
      </div>

      {/* Measuringmethod */}
      <div className="effect-detail-section-container">
        <div className="effect-detail-title-container">
          <BiotechOutlinedIcon/>
          <h2>Onderzoeksopzet</h2>
        </div>
        <table>
            <tr>
                <th>TITEL</th>
            </tr>
            {moments && moments.map(item => (
              <tr key={item.id}>
                  <td>
                    <p>{item.title}</p>
                </td>
            </tr>
            ))}
        </table>
      </div>
      
      {/* Questions */}
      <div className="effect-detail-section-container">
        <div className="effect-detail-title-container">
          <QuizOutlinedIcon/>
          <h2>Vragen</h2>
        </div>
          <table>
              <tr>
                  <th>TITLE</th>
                  <th>VRAAGTYPE</th>
                  <th>DETAILS</th>
              </tr>
              {questions && questions.map(item => (
                <tr key={item.id}>
                    <td>
                      <p>{item.title}</p>
                  </td>
                  <td>
                    <p>{item.type}</p>
                  </td>
                  <td>
                        <p className='pointer' onClick={() => navigate(`/questiondetail/${item.id}`)}>Bekijk</p>
                  </td>
              </tr>
              ))}
          </table>
        </div>

    </div>
</div>
  )
}

export default EffectDetail