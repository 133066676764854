import { Routes, Route} from "react-router-dom";
import Layout from '../components/Layout'

// Read
import Home from "../pages/read/Home";
import Partners from "../pages/read/Partners";
import Database from "../pages/read/Database";
import MkbaDetail from "../pages/read/MkbaDetail";
import Sources from "../pages/read/Sources";
import EffectDetail from "../pages/read/EffectDetail";
import About from "../pages/read/About";

// Edit
import EditSources from "../pages/edit/EditSources";
import EditMkba from "../pages/edit/EditMkba";
import EditEffect from "../pages/edit/EditEffect";
import Logs from "../pages/read/Logs";
import EditQuestion from "../pages/edit/EditQuestion";
import QuestionDetail from "../pages/read/QuestionDetail";
import EditDatabase from "../pages/edit/EditDatabase";

// Auth
import Login from "../pages/auth/Login";
import Profile from "../pages/auth/Profile";

const Navigation = () => {
  return (
    <Routes>
        <Route path={`/`} element={<Layout/>} >

            {/* Pages */}
            <Route exact path={`/`} element={<Home/>}/>
            <Route exact path={`/partners`} element={<Partners/>}/>
            <Route exact path={`/database`} element={<Database/>}/>
            <Route path={`/mkbadetail/:id`} element={<MkbaDetail/>}/>
            <Route path={`/effectdetail/:id`} element={<EffectDetail/>}/>
            <Route exact path={`/about`} element={<About/>}/>
            <Route path={`/sources/:id`} element={<Sources/>}/>

            {/* Edit */}
            <Route path={`/editmkba/:id`} element={<EditMkba/>}/>
            <Route path={`/editeffect/:id`} element={<EditEffect/>}/>
            <Route path={`/logs/:id`} element={<Logs/>}/>
            <Route path={`/editquestion/:id`} element={<EditQuestion/>}/>
            <Route path={`/questiondetail/:id`} element={<QuestionDetail/>}/>
            <Route path={`/editsources/:id`} element={<EditSources/>}/>
            <Route exact path={`/editdatabase/`} element={<EditDatabase/>}/>

            {/* Auth */}
            <Route exact path={`/login`} element={<Login/>}/>
            <Route path={`/profile/:id`} element={<Profile/>}/>
            
        </Route>
    </Routes>
  )
}

export default Navigation