import { useState, useContext} from 'react'
import { useFirestoreId, useFirestoreOne } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { useEffect } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { collection, addDoc, serverTimestamp, doc, updateDoc, deleteDoc } from "firebase/firestore"; 
import { db } from '../../firebase/config';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Sources = () => {

  const [title, setTitle] = useState('')

    const id = Location()[2]
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const mkbas = useFirestoreId('mkbas', id)
    const links = useFirestoreOne('links', 'mkba', id)

    console.log(links)

    useEffect(() => {
        mkbas && mkbas.forEach(item => {
            setTitle(item.title)
        })
    },[mkbas])

  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>Bronnen</h1>
            <h2>{title}</h2>
        </div>
        <div>
            <h2>Links</h2>
            <div className='table-container'>
                <table>
                    <tr>
                        <th>TITEL</th>
                        <th>LINK</th>
                        <th>CONCLUSIE</th>
                        <th>LAATST GEWIJZIGD</th>
                    </tr>
                    {links && links.map(item => (
                        <tr key={item.id}>
                            <td>
                                <p>{item.title}</p>
                            </td>
                            <td>
                                <a href={item.link} target='_blank'>Link</a>
                            </td>
                            <td>
                                <p>{item.conclusion}</p>
                            </td>
                            <td>
                                <p>{item.timestamp.toDate().toLocaleDateString("nl-NL", options)}</p>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    </div>
  )
}

export default Sources