import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import ButtonClicked from "../../hooks/ButtonClicked";
import { useNavigate } from "react-router-dom"
import { useState } from "react";

const Login = () => {
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
  
    const navigate = useNavigate()
  
    const login = (e) => {
  
      ButtonClicked(e, 'Inloggen')
  
      const auth = getAuth();
  
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
  
          navigate(`/`)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(errorMessage)
        });
    }
  
    const emailHandler = (e) => {
      const value = e.target.value 
  
      setEmail(value)
    }
  
    const passwordHandler = (e) => {
        const value = e.target.value 
  
        setPassword(value)
    }

  return (
    <div className='page-container'>
      <div className='page-title'>
        <h1>Login</h1>
        </div>
      <div className='login-register-container'>
          <p>Email</p>
          <input type="email" placeholder='Schrijf hier je email' onChange={emailHandler} />
          <p>Paswoord</p>
          <input type="password" placeholder='Schrijf hier je paswoord' onChange={passwordHandler} />
          <div className='button-container'>
              <button onClick={login}>Login</button>
          </div>
      </div>
  </div>
  )
}

export default Login