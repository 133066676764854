import banner from '../../assets/openmkba-banner.png'
import { useNavigate } from "react-router-dom"
import deccos from '../../assets/partners/deccos.png'
import alexanderimpact from '../../assets/partners/alexander-impact.png'

const Home = () => {

  const navigate = useNavigate()

  return (
    <div className='page-container'>

      <div id='header-section'>
        <div id='header-text'>
          <h1>De Social Impact Database is een openbare database van standaardisaties voor het meten van sociale impact. </h1>

          <div>
            <button onClick={() => navigate(`/database`)}>Bekijk database</button>
          </div>
        </div>
        <div id='img-div'>
          <img src={banner} alt="" />
        </div>
      </div>

      <div id='home-featured-partners-section'>
        <h2>Social Impact Database wordt o.a. gebruikt door</h2>
        <div id='home-logo-container'>
          <a href="https://deccos.nl/" target='_blank'>
              <img src={deccos} alt="deccos logo" />
          </a>
          <a href="https://alexanderimpact.nl/" target='_blank'>
              <img src={alexanderimpact} alt="alexander impact logo" />
          </a>
        </div>
        <a href="/partners"><p>Bekijk alle partners</p></a>
        
      </div>
        
    </div>
  )
}

export default Home