import { useFirestoreOneOrderBy} from "../../firebase/useFirestore"

const TotalMKBA = ({id}) => {

    const mkbas = useFirestoreOneOrderBy('mkbas', 'effect', id, 'position', 'asc')

    let sum = 0

    mkbas && mkbas.forEach(item => {
        if(item.currency === 'times'){
            sum = sum * item.amount
        } else if(item.currency === 'euro'){
            sum += item.amount
        } else if(item.currency === 'percentage'){
            sum = sum / 100 * item.amount
        }
    })

    return Number((Math.round(sum * 100) / 100).toFixed(2))
}

export default TotalMKBA