import Location from "../../hooks/Location"
import { useFirestoreId } from "../../firebase/useFirestore"
import { useState, useEffect } from "react"
import { collection, addDoc, serverTimestamp, doc, updateDoc, deleteDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase/config"
import { v4 as uuid } from 'uuid';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import EditTypeDetails from "../../components/questions/EditTypeDetails"
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';

const EditQuestion = () => {
    const [title, setTitle] = useState('')
    const [type, setType] = useState('')
    const [effect, setEffect] = useState('')
    const [options, setOptions] = useState([])
    const [scaleStart, setScaleStart] = useState('')
    const [scaleEnd, setScaleEnd] = useState('')
    const [startDescription, setStartDescription] = useState('')
    const [endDescription, setEndDescription] = useState('')
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])

    const id = Location()[2]

    const questions = useFirestoreId('questions', id)

    useEffect(() => {
        questions && questions.map(item => (
            setTitle(item.title),
            setType(item.type),
            setEffect(item.effect),
            setOptions(item.options),
            setScaleStart(item.scaleStart),
            setScaleEnd(item.scaleEnd),
            setStartDescription(item.startDescription),
            setEndDescription(item.endDescription),
            setRows(item.rows),
            setColumns(item.columns)
        ))
    }
    , [questions])

    const titleHandler = async (e) => {

        const docid = e.target.dataset.docid 

        await updateDoc(doc(db, "questions", docid), {
            title: e.target.value
          });
    }

    const descriptionHandler = async (e) => {

        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "questions", docid), {
            description: e.target.value
        });
    }

    const scaleStartHandler = async (e) => {

        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "questions", docid), {
            scaleStart: e.target.value
        });
    }

    const scaleEndHandler = async (e) => {

        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "questions", docid), {
            scaleEnd: e.target.value
        });
    }

    const startDescriptionHandler = async (e) => {

        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "questions", docid), {
            startDescription: e.target.value
        });
    }

    const endDescriptionHandler = async (e) => {

        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "questions", docid), {
            endDescription: e.target.value
        });
    }


  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>Vraag aanpassen</h1>
            <h3>{title}</h3>
        </div>
        <div>
            {questions && questions.map(item => (
                <div key={item.id}>

                    {/* Title */}
                    <div className="effect-detail-section-container">
                        <div className="effect-detail-title-container">
                            <TitleOutlinedIcon/>
                            <h2>Titel aanpassen</h2>
                        </div>
                        <input type="text" data-docid={item.docid}  defaultValue={item.title} onChange={titleHandler} />
                    </div>

                    {/* Description */}
                    <div className="effect-detail-section-container">
                        <div className="effect-detail-title-container">
                            <NotesOutlinedIcon/>
                            <h2>Omschrijving aanpassen</h2>
                        </div>
                        <input type="text" data-docid={item.docid}  defaultValue={item.description} onChange={descriptionHandler} />
                    </div>

                    {/* Type */}
                    <div className="effect-detail-section-container">
                        <div className="effect-detail-title-container">
                            <TuneOutlinedIcon/>
                            <h2>Type</h2>
                        </div>
                        <p>Schaalvraag</p>
                    </div>

                    {/* Answeroptions */}
                    <div className="effect-detail-section-container">
                        <div className="effect-detail-title-container">
                            <ListOutlinedIcon/>
                            <h2>Antwoordopties aanpassen</h2>
                        </div>
                        <div>
                            <p>Voeg een schaal toe</p>
                            <div id='scale-value-container'>
                                <select name="" id="" defaultValue={scaleStart} data-docid={item.docid} onChange={scaleStartHandler}>
                                    <option value="">-- Selecteer een onderwaarde --</option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                </select>
                                <p>t/m</p>
                                <select name="" id="" defaultValue={scaleEnd} data-docid={item.docid} onChange={scaleEndHandler}>
                                    <option value="">-- Selecteer een bovenwaarde --</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                            <div id='scale-value-container'>
                                <input type="text" placeholder='Schrijf hier de beschrijving' defaultValue={startDescription} data-docid={item.docid} onChange={startDescriptionHandler} />
                                <input type="text" placeholder='Schrijf hier de beschrijving' defaultValue={endDescription} data-docid={item.docid} onChange={endDescriptionHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
   
    </div>
  )
}

export default EditQuestion