import { useFirestoreOne, useFirestoreTwo } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { useState, useEffect } from "react"
import UserMeta from "../../components/UserMeta"

const Logs = () => {
    const [title, setTitle] = useState('')

    const id = Location()[2]
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const logs = useFirestoreOne('logs', 'parentId', id)
    const effects = useFirestoreTwo('effects', 'id', id, 'deleted', false)

    useEffect(() => {
        effects && effects.map(item => (
          setTitle(item.title)
        ))
    }, [effects])

    const type = (type) => {

        if (type === 'created') {
            return 'aangemaakt'
        }

        if (type === 'updated') {
            return 'aangepast'
        }

        if (type === 'deleted') {
            return 'verwijderd'
        }

        return 'onbekend'

    }

  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>Logs van {title}</h1>
        </div>
        <div>
            {logs && logs.map(item => (
                <div key={item.id} id='log-container'>
                    <p>Effect <i>{title}</i> is {type(item.type)}</p>
                    <p><UserMeta id={item.userId}/></p>
                    <p>op {item.timestamp.toDate().toLocaleDateString("nl-NL", options)}</p>
                </div>
            ))}
        </div>
     
    </div>
  )
}

export default Logs