import { useState} from 'react'
import { setDoc, serverTimestamp, doc, updateDoc, deleteDoc } from "firebase/firestore"; 
import { db } from '../../firebase/config';
import dragIcon from '../../assets/icons/drag-icon.png'

const DragAndDrop = ({children, item, input, parentIndex, collection, itemArray, setHoverIndex, hoverIndex}) => {
    const [docid, setDocid] = useState(null)
    const [dragIndex, setDragIndex] = useState(null)

    // Drag and drop
    const dragstart = (e) => {
        e.stopPropagation()
        const docid = e.target.dataset.docid
        const index = e.target.dataset.index

        const numberIndex = parseInt(index)

        setDocid(docid)
        setDragIndex(numberIndex)
        
    }

    const dragging = (e) => {
        e.stopPropagation()
        // e.target.style.backgroundColor = 'red'
    }

    const dragover = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const currentHoverIndex = e.target.dataset.index

        const numberHoverIndex = parseInt(currentHoverIndex)

        const parentRect = e.currentTarget.getBoundingClientRect();
        const offsetY = e.clientY - parentRect.top;
        const halfHeight = e.target.offsetHeight / 2;

        if (offsetY < halfHeight) {
            setHoverIndex(numberHoverIndex)
        } else {
            setHoverIndex(numberHoverIndex)
        }
    }

    const dragend = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        e.target.style.display = 'table-row'
    
        const arrayCopy = [...itemArray] // copy array
        arrayCopy.splice(dragIndex, 1) // remove item from array at dragIndex
        arrayCopy.splice(hoverIndex, 0, {docid: docid}) // add item to array at hoverIndex
        saveNewPositions(arrayCopy) // save new positions to firestore
      };

   const saveNewPositions = (arrayCopy) => {

        arrayCopy.forEach(async (item, index) => {

            await updateDoc(doc(db, collection, item.docid), {
                position: index + 1
            })
            .then(() => {
                console.log('position updated')
            })
        })
    }

  return (
    <tr 
    key={item.ID} 
    draggable='true' 
    data-index={parentIndex} 
    data-docid={item.docid}
    onDragStart={dragstart} 
    onDrag={dragging} 
    onDragOver={dragover}
    onDragEnd={dragend}
    >
        <td 
            draggable='false' 
            data-index={parentIndex} 
          >
            <div 
            className="table-item-title-container"
            data-index={parentIndex} 
            >
                <img 
                className="drag-icon"
                src={dragIcon} 
                alt="" 
                draggable='false' 
                data-index={parentIndex} 
                />
                <p 
                className="table-position-item" 
                data-index={parentIndex} 
                >{parentIndex + 1}
                </p>
                {input}
            </div>
        </td>
        {children}
    </tr>
  )
}

export default DragAndDrop