import { useFirestoreOneOrderBy, useFirestoreOne } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { useNavigate } from "react-router-dom"
import infoButton from '../../assets/icons/infromation-icon.png'
import { useState, useEffect } from "react"
import TotalMKBA from "../../components/mkba/TotalMKBA";

const MkbaDetail = () => {
    const [effectTitle, setEffectTitle] = useState('')

    const id = Location()[2]
    const navigate = useNavigate()

    const mkbas = useFirestoreOneOrderBy('mkbas', 'effect', id, 'position', 'asc')
    const effect = useFirestoreOne('effects', 'id', id)

     // Get the title of the effect
     useEffect(() => {
        effect && effect.map(item => (
            setEffectTitle(item.title)
        ))
    }
    , [effect])

  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>MKBA {effectTitle}</h1>
            <h2>€<TotalMKBA id={id}/></h2>
        </div>
        <div className='table-container'>
            <table>
                <tr>
                    <th>TITLE</th>
                    <th>TYPE</th>
                    <th>WAARDE</th>
                    <th>ONDERBOUWING</th>
                </tr>
                {mkbas && mkbas.map(item => (
                    <tr key={item.id}>
                    <td>
                        <p>{item.title}</p>
                   </td>
                   <td>
                        <p>{item.currency}</p>
                   </td>
                   <td>
                        <p>{item.amount}</p>
                   </td>
                   <td>
                        <img className='search-icon-mkba-table' id='sources-button' onClick={() => navigate(`/sources/${item.id}`)} src={infoButton} alt="" />
                   </td>
               </tr>
                ))}
            </table>
        </div>
    </div>
  )
}

export default MkbaDetail