import { Outlet } from 'react-router-dom'
import Bottembar from './Bottembar'
import Topbar from './Topbar'

const Layout = () => {
  return (
    <div className="layout-container">
        <div id='outlet-container'>
            <Topbar/>
            <Outlet />
            <Bottembar/>
        </div>
    </div>
  )
}

export default Layout