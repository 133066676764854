import { useFirestoreOne, useFirestoreOneOrderBy} from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { setDoc, serverTimestamp, doc, updateDoc, deleteDoc } from "firebase/firestore"; 
import { db } from '../../firebase/config';
import uuid from "react-uuid";
import plusButton from '../../assets/icons/add-icon.png'
import { useNavigate } from "react-router-dom"
import infoButton from '../../assets/icons/infromation-icon.png'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState, useEffect } from "react";
import TotalMKBA from "../../components/mkba/TotalMKBA";
import DragAndDrop from "../../components/common/DragAndDrop";

const EditMkba = () => {
    const [effectTitle, setEffectTitle] = useState('')
    const [hoverIndex, setHoverIndex] = useState(null)

    const id = Location()[2]
    const navigate = useNavigate()

    const mkbas = useFirestoreOneOrderBy('mkbas', 'effect', id, 'position', 'asc')
    const effect = useFirestoreOne('effects', 'id', id)
    const links = useFirestoreOne('links', 'effect', id)

    // Get the title of the effect
    useEffect(() => {
        effect && effect.map(item => (
            setEffectTitle(item.title)
        ))
    }
    , [effect])

    const titleHandler = async (e) => {

        const value = e.target.value
        const docid = e.target.dataset.docid
  
        await updateDoc(doc(db, 'mkbas', docid), {
          title: value
          })
    }

    // Add a specification
   const addSpecification = async (e) => {


    await setDoc(doc(db, 'mkbas', uuid()), {
        title: '',
        currency: 'euro',
        amount: Number(0),
        effect: id,
        type: '',
        id: uuid(),
        timestamp: serverTimestamp(),
        position: mkbas.length + 1,
        })

    }

    const deleteSpecification = async (e) => {

        const docid = e.target.dataset.docid

        await deleteDoc(doc(db, 'mkbas', docid))
    }

    // Update currency
    const currencyHandler = async (e) => {
        const value = e.target.options[e.target.selectedIndex].value
        const docid = e.target.dataset.docid 

        await updateDoc(doc(db, 'mkbas', docid), {
            currency: value
        })
 
        // setSaved('flex')
    }

    // Update amount of specification
    const amountHandler = async (e) => {
        const value = e.target.value 
        const docid = e.target.dataset.docid 

        await updateDoc(doc(db, 'mkbas', docid), {
            amount: Number(value)
        })
   
        // setSaved('flex')
    }

  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>MKBA {effectTitle}</h1>
            <h2>€<TotalMKBA id={id}/></h2>
        </div>
        <div className="add-item-container">
            <img src={plusButton} alt="" id='add-mkba-button' onClick={addSpecification} />
            <p onClick={addSpecification}>Regel toevoegen</p>
        </div>
        <div className='table-container'>
            <table>
                <tr>
                    <th>TITLE</th>
                    <th>TYPE</th>
                    <th>WAARDE</th>
                    <th>ONDERBOUWING</th>
                    <th>VERWIJDER</th>
                </tr>
                {mkbas && mkbas.map((item, index) => (
                    <DragAndDrop 
                        item={item}
                        input={
                            // <Tooltip content={activity.Activity} width='80%' top='-60px' >
                                <input contentEditable type="text" data-docid={item.docid} defaultValue={item.title} placeholder='Titel' onChange={titleHandler} />
                            // </Tooltip>
                        }
                        parentIndex={index}
                        collection={'mkbas'}
                        itemArray={mkbas}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={item.title}
                        >
                        <td>
                            <select name="" id="" defaultValue={item.currency} data-docid={item.docid} onChange={currencyHandler}>
                                    <option value="euro">€</option>
                                    <option value="times">X</option>
                                    <option value="percentage">%</option>
                                    <option value="text">Text</option>
                                </select>
                        </td>
                        <td>
                                <input type="number" defaultValue={item.amount} data-docid={item.docid}  onChange={amountHandler}/>
                        </td>
                        <td>
                                <img className='search-icon-mkba-table' id='sources-button' onClick={() => navigate(`/editSources/${item.id}`)} src={infoButton} alt="" />
                        </td>
                        <td>
                                <DeleteOutlineOutlinedIcon className='table-delete-icon' data-docid={item.docid} onClick={deleteSpecification}/>
                        </td>
                    </DragAndDrop>
                ))}
            </table>
        </div>
    </div>
  )
}

export default EditMkba