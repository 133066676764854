import Location from "../../hooks/Location"
import { useFirestoreId, useFirestoreOne } from "../../firebase/useFirestore"
import { useContext } from "react"
import { Auth } from "../../state/Auth"
import { useNavigate } from "react-router-dom"
import { signOut } from "firebase/auth";
import { auth as authO } from "../../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";

const Profile = () => {
    const auth = useContext(Auth) 

    const id = Location()[2]
    const navigate = useNavigate()

    const users = useFirestoreId('users', id)

    const logoutHandler = async (e) => {
  
      await signOut(authO)
  
      navigate(`/login`)
  
    }

    const resetPasswordHandler = async (e) => {

      await sendPasswordResetEmail(authO, auth[0].email)

      navigate(`/login`)

    }

  return (
    <div className='page-container'>
        {users && users.map(item => (
            <div key={item.id}>
                <h1>{item.name}</h1>
            </div>
        ))}
        <div>
          <button onClick={logoutHandler}>Uitloggen</button>
          <button onClick={resetPasswordHandler}>Wijzig wachtwoord</button>
          
        </div>
        <div>
          <h2>Beheer database</h2>
          <button onClick={() => navigate(`/editdatabase`)}>Klik hier</button>
        </div>
        
    </div>
  )
}

export default Profile