import { useState, useContext} from 'react'
import { useFirestoreId, useFirestoreOne } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { useEffect } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { collection, addDoc, serverTimestamp, doc, updateDoc, deleteDoc } from "firebase/firestore"; 
import { db } from '../../firebase/config';
import uuid from "react-uuid"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Auth } from "../../state/Auth"

const EditSources = () => {
    const auth = useContext(Auth) 

    const [docid, setDocid] = useState('')
    const [title, setTitle] = useState('')
    const [effect, setEffect] = useState('')

    const id = Location()[2]
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const mkbas = useFirestoreId('mkbas', id)
    const links = useFirestoreOne('links', 'mkba', id)

    useEffect(() => {
        mkbas && mkbas.forEach(item => {
            setDocid(item.docid)
            setTitle(item.title)
            setEffect(item.parentId)
        })
    },[mkbas])

    const addSource = async () => {

        await addDoc(collection(db, "links"), {
            title: '',
            link: '',
            timestamp: serverTimestamp(),
            id: uuid(),
            mkba: id,
            deleted: false,
            conclusion: ''
          });

          await addDoc(collection(db, "logs"), {
            type: 'link created',
            timestamp: serverTimestamp(),
            id: uuid(),
            parentId: effect,
            userId: auth[0].id
          });

    }

    const titleHandler = async (e) => {

        const title = e.target.value
        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "links", docid), {
            title: title
        });

        // await addDoc(collection(db, "logs"), {
        //     type: 'link title updated',
        //     timestamp: serverTimestamp(),
        //     id: uuid(),
        //     parentId: id,
        //     userId: auth[0].id
        //   });

    }

    const linkHandler = async (e) => {
            
        const link = e.target.value
        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "links", docid), {
            link: link
        });

        // await addDoc(collection(db, "logs"), {
        //     type: 'link link updated',
        //     timestamp: serverTimestamp(),
        //     id: uuid(),
        //     parentId: id,
        //     userId: auth[0].id
        // });

    }

    const conclusionHandler = async (e) => {

        const conclusion = e.target.value
        const docid = e.target.dataset.docid                                

        await updateDoc(doc(db, "links", docid), {
            conclusion: conclusion
        });

        // await addDoc(collection(db, "logs"), {
        //     type: 'link conclusion updated',
        //     timestamp: serverTimestamp(),
        //     id: uuid(),
        //     parentId: id,
        //     userId: auth[0].id
        // });

    }

  return (
    <div className='page-container'>
         <div className='page-title'>
            <h1>Bronnen</h1>
            <h2>{title}</h2>
        </div>
       
        <div>
            <h2>Links</h2>
            <AddCircleOutlineOutlinedIcon onClick={addSource}/>
            <div className='table-container'>
                <table>
                    <tr>
                        <th>TITEL</th>
                        <th>LINK</th>
                        <th>CONCLUSIE</th>
                        <th>DATUM</th>
                        <th>DELETE</th>
                    </tr>
                    {links && links.map(item => (
                        <tr key={item.id}>
                            <td>
                                <input type="text" data-docid={item.docid} defaultValue={item.title} onChange={titleHandler}/>
                            </td>
                            <td>
                                <input type="text" data-docid={item.docid} defaultValue={item.link} onChange={linkHandler}/>
                            </td>
                            <td>
                                <input type="text" data-docid={item.docid} defaultValue={item.conclusion} onChange={conclusionHandler}/>  
                            </td>
                            <td>
                                <p>{item.timestamp?.toDate().toLocaleDateString("nl-NL", options)}</p>
                            </td>
                            <td>
                                <DeleteOutlineOutlinedIcon className='pointer' onClick={() => deleteDoc(doc(db, "links", item.docid))}/>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    </div>
  )
}

export default EditSources