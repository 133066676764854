import { useFirestoreId } from "../firebase/useFirestore"

const UserMeta = ({id}) => {

    const users = useFirestoreId('users', id)
  return (
    <div>
        {users && users.map(item => (
            <p key={item.id}>door {item.name}</p>
        ))}
    </div>
  )
}

export default UserMeta