import { db } from './config'
import { useState, useEffect} from 'react';
import { collection, query, where, getDocs, orderBy, onSnapshot, limit } from "firebase/firestore"; 

const useFirestore = (collect) => {
    const [docs, setDocs] = useState([])

    const col = collection(db, collect);

    useEffect(() => {

        const unsubscribe = onSnapshot(col, (querySnapshot) => {

            const docArray = [];

            querySnapshot.forEach((doc) => {
                docArray.push({...doc.data(), docid: doc.id});
            });  

            setDocs(docArray)
    
        })
        return () => unsubscribe()

    },[collect])

    return docs

}

const useFirestoreId = (collect, id) => {
    const [docs, setDocs] = useState([])

    const col = collection(db, collect);
    const q = query(col, where("id", '==', id))

    useEffect(() => {

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const docArray = [];

            querySnapshot.forEach((doc) => {
                docArray.push({...doc.data(), docid: doc.id});
            });  

            setDocs(docArray)
    
        })
        return () => unsubscribe()

    },[collect, id])

    return docs

}

const useFirestoreOne = (collect, key, value) => {
    const [docs, setDocs] = useState([])

    const col = collection(db, collect);
    const q = query(col, where(key, '==', value))

    useEffect(() => {

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const docArray = [];

            querySnapshot.forEach((doc) => {
                docArray.push({...doc.data(), docid: doc.id});
            });  

            setDocs(docArray)
    
        })
        return () => unsubscribe()

    },[collect, key, value])

    return docs

}

const useFirestoreTwo = (collect, key, value, key2, value2) => {
    const [docs, setDocs] = useState([])

    const col = collection(db, collect);
    const q = query(col, where(key, '==', value), where(key2, '==', value2))

    useEffect(() => {

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const docArray = [];

            querySnapshot.forEach((doc) => {
                docArray.push({...doc.data(), docid: doc.id});
            });  

            setDocs(docArray)
    
        })
        return () => unsubscribe()

    },[collect, key, value, key2, value2])

    return docs

}

const useFirestoreOneOrderBy = (collect, key, value, key2, direction) => {
    const [docs, setDocs] = useState([])

    const col = collection(db, collect);
    const q = query(col, where(key, '==', value), orderBy(key2, direction))

    useEffect(() => {

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const docArray = [];

            querySnapshot.forEach((doc) => {
                docArray.push({...doc.data(), docid: doc.id});
            });  

            setDocs(docArray)
    
        })
        return () => unsubscribe()

    },[collect, key, value, key2, direction])

    return docs

}

const useFirestoreTwoOrderBy = (collect, key, value, key2, value2, key3, direction) => {
    const [docs, setDocs] = useState([])

    const col = collection(db, collect);
    const q = query(col, where(key, '==', value), where(key2, '==', value2), orderBy(key3, direction))

    useEffect(() => {

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const docArray = [];

            querySnapshot.forEach((doc) => {
                docArray.push({...doc.data(), docid: doc.id});
            });  

            setDocs(docArray)
    
        })
        return () => unsubscribe()

    },[collect, key, value, key2, value2, key3, direction])

    return docs

}

export { 
    useFirestore,
    useFirestoreId,
    useFirestoreOne,
    useFirestoreTwo,
    useFirestoreOneOrderBy,
    useFirestoreTwoOrderBy 
}