import cmostamm from '../../assets/partners/cmo-stamm.png'
import deccos from '../../assets/partners/deccos.png'
import servates from '../../assets/partners/servates.png'
import alexanderimpact from '../../assets/partners/alexander-impact.png'

const Partners = () => {
  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>Partners</h1>
        </div>
        <div id='partner-container'>
            <a href="https://deccos.nl/" target='_blank'>
                <img src={deccos} alt="deccos logo" />
            </a>
            <a href="https://cmostamm.nl/" target='_blank'>
                <img src={cmostamm} alt="cmo stamm logo" />
            </a>
            <a href="https://alexanderimpact.nl/" target='_blank'>
                <img src={alexanderimpact} alt="alexander impact logo" />
            </a>
            <a href="https://https://servates.nl/" target='_blank'>
                <img src={servates} alt="servates logo" />
            </a>
        </div>

    </div>
  )
}

export default Partners