import React from 'react'

const About = () => {
  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>Over Social Impact Database</h1>
        </div>
        <p>De Social Impact Database is in 2023 geinitieerd door <a href="https://www.deccos.nl" target='_blank'>Deccos </a> 
        en <a href="https://alexanderimpact.nl/" target='_blank'> Alexander Impact</a> met als doel om standardisaties te creëeren voor het 
        meten van sociale impact.</p>
        <p>
          Er is gekozen voor een openbare database zodat er volledige 
          transparatie is over de tot standkoming van alle standardisaties en de aannames die hierin zijn gemaakt.
        </p>

        <h2>Waarom standaardiseren?</h2>
        <p>
          Zonder standaardisaties is het niet mogelijk om te benchmarken en zodoende inzicht te krijgen in welke sociale interventies het meest effectief zijn.
        </p>
    </div>
  )
}

export default About