import Logo from '../assets/logoNewBlue.png'
import { useNavigate } from "react-router-dom"
import { useContext } from 'react';
import avatar from '../assets/icons/avatar-icon.png'
import { Auth } from '../state/Auth';

const Topbar = () => {
  const [user] = useContext(Auth)

  const navigate = useNavigate()
  
  return (
    <div id='topbar-container'>
        <img id='logo-topbar' src={Logo} alt="Logo openmkba" onClick={() => navigate(`/`)}/>
        <div className='navigation'>
          <a href="/database">Database</a>
          <a href="/partners">Partners</a>
          <a href="/about">Over</a>
        </div>
        <button id='login-button' style={{display: user ? 'none' : 'block'}} onClick={() => navigate(`/login`)}>Inloggen</button>
        <div className='auth-container' style={{display: user ? 'flex' : 'none'}} onClick={() => navigate(`/profile/${user.id}`)}>
          <img src={avatar} alt="" />
          <p>{user.name}</p>
        </div>
    </div>
  )
}

export default Topbar