import { useFirestoreTwo } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { useState, useEffect } from "react"
import { collection, addDoc, serverTimestamp, doc, updateDoc, deleteDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase/config"
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { v4 as uuid } from 'uuid';
import { useNavigate } from "react-router-dom"
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import TotalMKBA from "../../components/mkba/TotalMKBA";

const EditEffect = () => {
  const [title, setTitle] = useState('')
  const [docid, setDocid] = useState('')
  const [targetgroup, setTargetgroup] = useState('')
  const [sdgs, setSdgs] = useState([])
  const [sectors, setSectors] = useState([])

  const id = Location()[2]
  const navigate = useNavigate()

  const effects = useFirestoreTwo('effects', 'id', id, 'deleted', false)
  const questions = useFirestoreTwo('questions', 'effect', id, 'deleted', false)
  const moments = useFirestoreTwo('moments', 'effect', id, 'deleted', false)

  useEffect(() => {
      effects && effects.map(item => (
        setTitle(item.title),
        setDocid(item.docid),
        setTargetgroup(item.targetgroup),
        setSdgs(item.sdg),
        setSectors(item.sector)
      ))
  }, [effects])

  const titleHandler = async (e) => {

    await updateDoc(doc(db, "effects", docid), {
      title: e.target.value
    });

  }

  const targetgroupHandler = async (e) => {
      
      await updateDoc(doc(db, "effects", docid), {
        targetgroup: e.target.value
      });
  
    }


  const addQuestion = async () => {

    await addDoc(collection(db, "questions"), {
      title: 'Nieuwe vraag',
      timestamp: serverTimestamp(),
      id: uuid(),
      effect: id,
      deleted: false,
      Type: ''
    });

  }

  const sdgHandler = async (e) => {

    const sdg = e.target.value

    const isSelected = e.target.checked

    if(isSelected) {  
      await updateDoc(doc(db, "effects", docid), {
        sdg: arrayUnion(sdg)
      });
    }

    if(!isSelected) {
      await updateDoc(doc(db, "effects", docid), {
        sdg: arrayRemove(sdg)
      });
    }

  }

  const sectorHandler = async (e) => {

    const sector = e.target.value

    const isSelected = e.target.checked

    if(isSelected) {
      await updateDoc(doc(db, "effects", docid), {
        sector: arrayUnion(sector)
      });
    }

    if(!isSelected) {
      await updateDoc(doc(db, "effects", docid), {
        sector: arrayRemove(sector)
      });
    }

  }

  const questionTitleHandler = async (e) => {

    const docid = e.target.dataset.docid

    await updateDoc(doc(db, "questions", docid), {
      title: e.target.value
    });

  }

  const questionTypeHandler = async (e) => {
      
      const docid = e.target.dataset.docid
  
      await updateDoc(doc(db, "questions", docid), {
        type: e.target.value
      });
  
  }

  const questionDescriptionHandler = async (e) => {

    const docid = e.target.dataset.docid

    await updateDoc(doc(db, "questions", docid), {
      description: e.target.value
    });

  }

  const momentTitleHandler = async (e) => {

    const docid = e.target.dataset.docid

    await updateDoc(doc(db, "moments", docid), {
      title: e.target.value
    });

  }

  const addMoment = async () => {

    await addDoc(collection(db, "moments"), {
      title: 'Nieuw meetmoment',
      timestamp: serverTimestamp(),
      id: uuid(),
      effect: id,
      deleted: false
    });

  }

  return (
    <div className='page-container'>
        <div className='page-title'>
            <h1>{title} aanpassen</h1>
        </div>
        <div>
            {/* Title */}
          <div className="effect-detail-section-container">
            <div className="effect-detail-title-container">
              <TitleOutlinedIcon/>
              <h2>Titel aanpassen</h2>
            </div>
            <input type="text" placeholder={title} onChange={titleHandler} />
          </div>

          {/* Targetgroup */}
          <div className="effect-detail-section-container">
            <div className="effect-detail-title-container">
              <Groups2OutlinedIcon/>
              <h2>Doelgroep</h2>
            </div>
            <input type="text" placeholder={targetgroup} onChange={targetgroupHandler} />
          </div>

          {/* SDG's */}
          <div className="effect-detail-section-container">
            <div className="effect-detail-title-container">
              <WorkspacesOutlinedIcon/>
              <h2>SDG's</h2>
            </div>
            <div id='sdg-selector'>
              <div>
                <input type="checkbox" value='1' checked={sdgs.includes('1')}  onChange={sdgHandler}/>
                <label>1. Geen armoede</label>
              </div>
              <div>
                <input type="checkbox" value='2' checked={sdgs.includes('2')} onChange={sdgHandler}/>
                <label>2. Geen honger</label>
              </div>
              <div>
                <input type="checkbox" value='3' checked={sdgs.includes('3')} onChange={sdgHandler}/>
                <label>3. Goede gezondheid en welzijn</label>
              </div>
              <div>
                <input type="checkbox" value='4' checked={sdgs.includes('4')} onChange={sdgHandler}/>
                <label>4. Kwaliteitsonderwijs</label>
              </div>
              <div>
                <input type="checkbox" value='5' checked={sdgs.includes('5')} onChange={sdgHandler}/>
                <label>5. Gendergelijkheid</label>
              </div>
              <div>
                <input type="checkbox" value='8' checked={sdgs.includes('8')} onChange={sdgHandler}/>
                <label>8. Eerlijk werk en economische groei</label>
              </div>
              <div>
                <input type="checkbox" value='10' checked={sdgs.includes('10')} onChange={sdgHandler}/>
                <label>10. Ongelijkheid verminderen</label>
              </div>
              <div>
                <input type="checkbox" value='16' checked={sdgs.includes('16')} onChange={sdgHandler}/>
                <label>16. Vrede, justitie en sterke publieke diensten</label>
              </div>
              <div>
                <input type="checkbox" value='17' checked={sdgs.includes('17')} onChange={sdgHandler}/>
                <label>17. Partnerschap om doelstellingen te bereiken</label>
              </div>
            </div>
          </div>

          {/* Sectors */}
          <div className="effect-detail-section-container">
            <div className="effect-detail-title-container">
              <AddHomeWorkOutlinedIcon/>
              <h2>Sectoren</h2>
            </div>
            <div id='sector-selector'>
              <div>
                <input type="checkbox" value='Gezondheidszorg en Welzijn' checked={sectors.includes('Gezondheidszorg en Welzijn')} onChange={sectorHandler} />
                <label>Gezondheidszorg en Welzijn</label>
              </div>
              <div>
                <input type="checkbox" value='Onderwijs' checked={sectors.includes('Onderwijs')} onChange={sectorHandler} />
                <label>Onderwijs</label>
              </div>
              <div>
                <input type="checkbox" value='Armoedebestrijding' checked={sectors.includes('Armoedebestrijding')} onChange={sectorHandler}/>
                <label>Armoedebestrijding</label>
              </div>
              <div>
                <input type="checkbox" value='Mensenrechten en Sociale Gerechtigheid' checked={sectors.includes('Mensenrechten en Sociale Gerechtigheid')} onChange={sectorHandler}/>
                <label>Mensenrechten en Sociale Gerechtigheid</label>
              </div>
              <div>
                <input type="checkbox" value='Cultuur en Kunst' checked={sectors.includes('Cultuur en Kunst')} onChange={sectorHandler}/>
                <label>Cultuur en Kunst</label>
              </div>
              <div>
                <input type="checkbox" value='Internationale Ontwikkeling' checked={sectors.includes('Internationale Ontwikkeling')} onChange={sectorHandler}/>
                <label>Internationale Ontwikkeling</label>
              </div>
              <div>
                <input type="checkbox" value='Technologie en Sociale Innovatie' checked={sectors.includes('Technologie en Sociale Innovatie')} onChange={sectorHandler}/>
                <label>Technologie en Sociale Innovatie</label>
              </div>
            
            </div>
          </div>

          {/* MKBA */}
          <div className="effect-detail-section-container">
            <div className="effect-detail-title-container">
              <EuroOutlinedIcon/>
              <h2>MKBA</h2>
            </div>
            <p>Totale waarde per output:</p>
            <p><b>€<TotalMKBA id={id}/></b></p>
            <div>
              <button onClick={() => navigate(`/editmkba/${id}`)}>Aanpassen</button>
            </div>
          </div>

          {/* Measuringmethod */}
          <div className="effect-detail-section-container">
            <div className="effect-detail-title-container">
              <BiotechOutlinedIcon/>
              <h2>Onderzoeksopzet</h2>
            </div>
            <div className="add-icon-container" onClick={addMoment}>
              <AddCircleOutlineOutlinedIcon/>
              <p>Meetmoment toevoegen</p>
            </div>
            <table>
                <tr>
                    <th>TITEL</th>
                    <th>VERWIJDER</th>
                </tr>
                {moments && moments.map(item => (
                  <tr key={item.id}>
                      <td>
                        <input type="text" data-docid={item.docid} defaultValue={item.title} onChange={momentTitleHandler}/>
                    </td>
                    <td>
                      <DeleteOutlineOutlinedIcon className='pointer' onClick={() => deleteDoc(doc(db, "moments", item.docid))}/>
                    </td>
                </tr>
                ))}
            </table>
          </div>
          
          {/* Questions */}
          <div className="effect-detail-section-container">
            <div className="effect-detail-title-container">
              <QuizOutlinedIcon/>
              <h2>Vragen</h2>
            </div>
            <div className="add-icon-container" onClick={addQuestion}>
                <AddCircleOutlineOutlinedIcon/>
                <p>Vraag toevoegen</p>
              </div>
              <table>
                  <tr>
                      <th>TITLE</th>
                      <th>VRAAGTYPE</th>
                      <th>AANPASSEN</th>
                      <th>VERWIJDER</th>
                  </tr>
                  {questions && questions.map(item => (
                    <tr key={item.id}>
                        <td>
                          <input type="text" data-docid={item.docid} defaultValue={item.title} onChange={questionTitleHandler}/>
                      </td>
                      <td>
                            <select defaultValue={item.type} data-docid={item.docid} onChange={questionTypeHandler}>
                              <option value="">-- Selecteer vraagtype --</option>
                              <option value="open">Open vraag</option>
                              <option value="scale">Schaal vraag</option>
                              <option value="multiple-one">Meerkeuze vraag (een antwoord)</option>
                              <option value="multiple-multi">Meerkeuze vraag (meerdere antwoorden)</option>
                              <option value="matrix">Matrix vraag</option>
                            </select>
                      </td>
                      <td>
                        <EditOutlinedIcon className='pointer' onClick={() => navigate(`/editquestion/${item.id}`)}/>
                      </td>
                      <td>
                        <DeleteOutlineOutlinedIcon className='pointer' onClick={() => deleteDoc(doc(db, "questions", item.docid))}/>
                      </td>
                  </tr>
                  ))}
              </table>
            </div>
        </div>
    </div>
  )
}

export default EditEffect