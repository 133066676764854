import { useFirestoreOne } from '../../firebase/useFirestore'

const ResearchOverview = ({id}) => {

    const moments = useFirestoreOne('moments', 'effect', id)
    const questions = useFirestoreOne('questions', 'effect', id)

  return (
    <div>
        <p>Meetmomenten: {moments.length }</p>
        <p>Vragen: {questions.length }</p>
    </div>
  )
}

export default ResearchOverview